<template>
    <base-layout title="ExpertHub" :logo="logo">
        <el-aside width="180px">
            <aside-menu :default-active="$route.path" :menuItems="menuItems" />
        </el-aside>
        <el-main>
            <router-view></router-view>
        </el-main>
    </base-layout>
</template>

<script>
    import Logo from "@/assets/mlogo.png"
    import BaseLayout from "./components/baseLayout"
    import AsideMenu from "./components/asideMenu"
    export default {
        components: {
            BaseLayout,
            AsideMenu
        },
        data() {
            return {
                logo: Logo,
                menuItems: []
            }
        },
        mounted() {
            this.$http.get('/menu')
            .then(resp=>{
                console.log(resp.data)
                this.menuItems = resp.data
            }).catch(err=>{
                this.$message.error('获取菜单失败'+err.message)
            })
        }
    }
</script>

<style lang="scss" scoped>
    .el-aside {
        /*background-color: #eeeeee;*/
        color: #333;
        border-right: 1px solid #e6e6e6;
    }
</style>
